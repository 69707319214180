<template>
	<a-space direction="vertical">
		<a-card>
			<div slot="title">
				<a-button slot="extra" type="link" @click="$router.back()">
					<a-icon type="left" />
					返回
				</a-button>
				<a-divider type="vertical" />
				{{ cardTitle }}
			</div>

			<a-form-model ref="form" :model="formdata" v-bind="layout">
				<a-form-model-item label="用户ID">
					<span>
						{{ info.id }}
						<a href="javascript:;" v-clipboard:copy="info.id" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					</span>
				</a-form-model-item>
				<a-form-model-item label="用户信息">
					<a-avatar :size="64" :src="info.headimgurl"></a-avatar>
					<div>
						<a-space>
							{{ info.nickname }}
							<span v-if="info.userRemark">({{ info.userRemark }})</span>
							<a href="javascript:;" @click="onEdit('user_remark')">{{ info.userRemark ? '修改备注' : '新增备注' }}</a>
						</a-space>
					</div>
				</a-form-model-item>
				<a-form-model-item label="用户手机号">
					<a-space>
						{{ info.mobile }}
						<a href="javascript:;" v-clipboard:copy="info.mobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
						<a href="javascript:;" @click="onEdit('mobile')">修改</a>
					</a-space>
				</a-form-model-item>

				<a-form-model-item label="用户等级">
					<a-space>
						<span v-if="info.identify === 1" style="color:red">{{ info.roleIdStr }}</span>
						<span v-else>{{ info.roleIdStr }}</span>
					</a-space>
				</a-form-model-item>

				<a-form-model-item label="微信授权" prop="unbound">
					<a-space>
						<span>{{ info.unbound == 1 ? '已授权' : '未授权' }}</span>
						<a-button type="danger" size="small" @click="onUnbindWechat" v-if="info.unbound == 1">解绑微信</a-button>
					</a-space>
				</a-form-model-item>

				<a-form-model-item label="用户余额">
					<a-space :size="20">
						{{ info.money }}
						<a href="javascript:;" @click="onBalance(1)">明细</a>
						<a href="javascript:;" v-if="info.isShow == 1" @click="onBalanceForm(6)">操作</a>
					</a-space>
				</a-form-model-item>


				<a-form-model-item label="千年艾余额">
					<a-space :size="20">
						{{ info.mugwordMoney }}
						<a href="javascript:;" @click="onBalance(2)">明细</a>
						<a href="javascript:;" v-if="info.isShow == 1" @click="onBalanceForm(8)">操作</a>
					</a-space>
				</a-form-model-item>

				<a-form-model-item label="钱包余额" v-if="!!info.fastPayAccount">
					<a-space :size="20">
						{{ info.fastPayAccount }}
						<a href="javascript:;" @click="onBalance(3)">明细</a>
					</a-space>
				</a-form-model-item>

				<a-form-model-item label="保证金">
					<span>{{ info.bond }}</span>
				</a-form-model-item>

				<a-form-model-item label="推荐经销商数量" prop="vipNum" v-show="info.roleId > 0">
					<a-space :size="20">
						{{ info.vipNum || 0 }}
						<a href="javascript:;" @click="onEdit('vipNum')">修改</a>
					</a-space>
				</a-form-model-item>

				<a-form-model-item label="库存数量">
					<a-popover title="请选择" trigger="click">
						<a-space slot="content">
							<a-button type="default" @click="onEdit('roleId')">修改用户等级</a-button>
							<a-button type="primary" @click="onEdit('stock')">修改库存数量</a-button>
						</a-space>
						<a-button type="link">修改用户等级/库存数量</a-button>
					</a-popover>

					<a-list bordered size="small" item-layout="horizontal" :data-source="info.goodsNum || []" style="max-width:700px;max-height:396px;overflow-y:auto;">
						<a-list-item slot="renderItem" slot-scope="item, index">
							<a-list-item-meta :title="item.productName">
								<a-avatar shape="square" style="width:60px;height:48px" slot="avatar" :src="item.banner" />
								<a-space slot="description" :size="24">
									<a-tag color="green">
										云库存:
										<b>{{ item.goodsNum }}</b>
									</a-tag>
									<a-tag color="orange">
										实体库存:
										<b>{{ item.entityNum }}</b>
									</a-tag>
								</a-space>
							</a-list-item-meta>
							<a slot="actions" @click="onStock(1, item)">云库存明细</a>
							<a slot="actions" @click="onStock(2, item)">实体库存明细</a>
						</a-list-item>
					</a-list>
				</a-form-model-item>

				<a-form-model-item :label="`${info.month}市场服务费`">
					<a-space :size="20">
						<span>{{ info.serviceCharge }}</span>
						<a href="javascript:;" @click="onEdit('service_charge')">修改</a>
					</a-space>
				</a-form-model-item>

				<a-form-model-item label="注册时间">
					<span>{{ info.ctime }}</span>
					<a href="javascript:;" @click="onSuperiors()" style="margin-left: 20px;">上级列表</a>
				</a-form-model-item>

				<a-form-model-item label="vip开始时间" v-if="info.roleId > 0">
					<span>{{ info.vipStartTime }}</span>
					<a href="javascript:;" @click="onUpgrade()" style="margin-left: 20px;">明细</a>
				</a-form-model-item>

				<a-form-model-item label="等级最后修改时间" v-if="info.roleId > 0">
					<span>{{ info.lastTime }}</span>
				</a-form-model-item>

				<a-form-model-item label="最后登录时间">
					<span>{{ info.loginTime }}</span>
				</a-form-model-item>

				<a-form-model-item label="是否实名">
					<a-space :size="20">
						<span>{{ info.realName == 1 ? '已实名' : '未实名' }}</span>
						<span v-if="info.realName == 1"></span>
						<a-popconfirm v-else title="确定修改为已实名？" @confirm="onUpdateRealName"><a href="javascript:;">修改</a></a-popconfirm>
					</a-space>
				</a-form-model-item>
				<a-form-model-item label="真实姓名" v-if="info.realName == 1 && info.userRealName">
					<span>{{ info.userRealName }} （{{ info.userRealTime }}）</span>
				</a-form-model-item>
				<a-form-model-item label="邀请人" v-if="!!info.parentId">
					<div>
						{{ info.parentId }}
						<a href="javascript:;" v-clipboard:copy="info.parentId" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
						<br />
						{{ info.parentName }} （ {{ info.parentMobile }} ）
					</div>
				</a-form-model-item>
				<a-form-model-item label="团队长" v-if="!!info.teamId">
					<div>
						{{ info.teamId }}
						<a href="javascript:;" v-clipboard:copy="info.teamId" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
						<br />
						{{ info.teamNickname }} （ {{ info.teamMobile }} ）
					</div>
				</a-form-model-item>
				<a-form-model-item label="用户备注">
					<a-space>
						<a-popover placement="top" trigger="click" v-if="remarks.length > 0">
							<template slot="content">
								<a-table :data-source="remarks || []" :pagination="false" size="small" style="width:560px;" :scroll="{ y: 300 }">
									<a-table-column key="index" title="序号" data-index="index" />
									<a-table-column key="remarks" title="描述" data-index="remarks" :width="280" />
									<a-table-column key="ctime" title="操作时间" data-index="ctime" :width="180" />
								</a-table>
							</template>
							<a-button type="link">查看</a-button>
						</a-popover>
						<span v-else>暂无数据</span>
						<a href="javascript:;" @click="onEdit('remarks')">添加备注</a>
					</a-space>
				</a-form-model-item>
			</a-form-model>
		</a-card>

		<a-modal v-drag-modal :title="modalUpdate.title" v-model="modalUpdate.visible" :bodyStyle="{ padding: '10px' }" :width="['stock', 'stock_super', 'commission','commission_confirm'].includes(modalUpdate.type) ? 1024 : 600" :mask-closable="false">
			<a-form-model ref="formUpdate" :model="modalUpdate.formdata" :rules="modalUpdate.rules" v-bind="layout">
				<template v-if="modalUpdate.type == 'user_remark'">
					<a-form-model-item label="用户备注" prop="user_remark"><a-input v-model="modalUpdate.formdata.user_remark" placeholder="请输入用户备注" /></a-form-model-item>
				</template>
				<template v-if="modalUpdate.type == 'mobile'">
					<a-form-model-item label="手机号码" prop="mobile"><a-input v-model="modalUpdate.formdata.mobile" placeholder="请输入手机号码" /></a-form-model-item>
				</template>
				<template v-if="modalUpdate.type == 'roleId'">
					<a-form-model-item label="用户等级" prop="roleId" required>
						<a-select v-model="modalUpdate.formdata.roleId" placeholder="请选择用户等级" style="width:100%">
							<a-select-option v-for="(val, key) in ROLES" :key="+key" :value="+key">{{ val }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</template>
				<template v-if="modalUpdate.type == 'bondType'">
					<a-form-model-item label="保证金类型" prop="bondType" :labelCol="{ span: 6 }">
						<a-radio-group v-model="modalUpdate.formdata.bondType">
							<a-radio :value="0">需要</a-radio>
							<a-radio :value="1">不需要</a-radio>
						</a-radio-group>
					</a-form-model-item>
				</template>
				<template v-if="modalUpdate.type == 'vipNum'">
					<a-form-model-item label="推荐经销商数量" prop="vipNum" :labelCol="{ span: 6 }" required>
						<a-input-number v-model="modalUpdate.formdata.vipNum" :min="0" placeholder="请输入新增经销商数量" style="width:200px" />
					</a-form-model-item>
				</template>

				<template v-if="modalUpdate.type == 'stock'">
					<a-row>
						<a-col :span="12">
							<a-form-model-item label="订单金额" prop="orderMoney" :labelCol="{ span: 6 }">
								<a-input v-model="modalUpdate.formdata.orderMoney" :min="0" placeholder="请输入订单金额" style="width:200px" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="公司收款金额" prop="companyMoney" :labelCol="{ span: 6 }">
								<a-input v-model="modalUpdate.formdata.companyMoney" :min="0" placeholder="请输入公司收款金额" style="width:200px" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="保证金" prop="earnestMoney" :labelCol="{ span: 6 }">
								<a-input v-model="modalUpdate.formdata.earnestMoney" :min="0" placeholder="请输入保证金" style="width:200px" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="是否添加库存" prop="inventory" :labelCol="{ span: 6 }">
								<a-radio-group v-model="modalUpdate.formdata.inventory">
									<a-radio :value="1"> 新增 </a-radio>
									<a-radio :value="2"> 否</a-radio>
								</a-radio-group>
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-table :data-source="modalUpdate.formdata.stocks" :pagination="false" :scroll="{y: 420}">
						<a-table-column key="productName" title="产品" data-index="productName" />
						<a-table-column key="cloudNum" data-index="cloudNum" align="center">
							<span slot="title" style="color: #1890ff">云库存购买数量</span>
							<template slot-scope="status, record">
								<a-input placeholder="请输入云库存数量" v-model="record.cloudNum"></a-input>
							</template>
						</a-table-column>
						<a-table-column key="cloudNumGive" data-index="cloudNumGive" align="center">
							<span slot="title" style="color: #1890ff">云库存赠送数量</span>
							<template slot-scope="status, record">
								<a-input placeholder="请输入云库存赠送数量" v-model.number="record.cloudNumGive"></a-input>
							</template>
						</a-table-column>
						<a-table-column key="entityNum" data-index="entityNum" align="center">
							<span slot="title" style="color: #1890ff">实体库存数量</span>
							<template slot-scope="status, record">
								<a-input placeholder="请输入实体库存数量" v-model="record.entityNum"></a-input>
							</template>
						</a-table-column>
					</a-table>
				</template>
				<template v-if="modalUpdate.type == 'stock_super'">
					<a-table :data-source="modalUpdate.formdata.supers" :pagination="false" :scroll="{ x: 'max-content' }">
						<a-table-column key="userId" title="选择" data-index="userId" align="center" fixed="left">
							<template slot-scope="userId">
								<a-radio :checked="modalUpdate.formdata.senderUid === userId" @click="modalUpdate.formdata.senderUid = userId"></a-radio>
							</template>
						</a-table-column>
						<a-table-column key="nickname" title="用户信息" data-index="nickname" :width="140">
							<template slot-scope="text, record">
								<div v-if="!!record.nickname" :style="{color:record.identify === 1?'red':''}">
									{{ record.nickname }}
									<br />
									{{ record.mobile }}
								</div>
							</template>
						</a-table-column>
						<a-table-column key="roleStr" title="用户等级" data-index="roleStr" align="center" :width="120"></a-table-column>
						<a-table-column v-for="item in info.goodsNum" :key="item.id" :title="productNameMap[item.productId]" :width="180">
							<template slot-scope="text, record">
								<div>
									<b><span style="display:inline-block;width:80px;text-align:right">云库存:</span></b>
									{{ record['totalNum' + productIdMap[item.productId]] }}
									<br />
									<span style="display:inline-block;width:80px;text-align:right">实体库存:</span>
									{{ record['entityNum' + productIdMap[item.productId]] }}
								</div>
							</template>
						</a-table-column>
					</a-table>
				</template>
				<template v-if="modalUpdate.type == 'stock_type' && modalUpdate.formdata.types.length > 0">
					<a-form-model-item label="产品类型" prop="types">
						<a-cascader :getPopupContainer="e => e.parentNode" :value="modalUpdate.formdata.itemOne ? [modalUpdate.formdata.itemOne, modalUpdate.formdata.itemTwo] : null" :options="modalUpdate.formdata.types" placeholder="请选择产品类型" :fieldNames="{ label: 'name', value: 'id', children: 'children' }" @change="([itemOne, itemTwo]) => Object.assign(modalUpdate.formdata, { itemOne, itemTwo })" />
					</a-form-model-item>
				</template>
				<template v-if="modalUpdate.type == 'stock_fee'">
					<a-table rowKey="userId" :row-selection="{ selectedRowKeys: modalUpdate.formdata.serviceUids, onChange: selectedRowKeys => (modalUpdate.formdata.serviceUids = selectedRowKeys) }" :data-source="modalUpdate.formdata.services" :pagination="false">
						<a-table-column key="nickname" title="用户信息" data-index="nickname" :width="180">
							<template slot-scope="text, record">
								<div v-if="!!record.nickname" :style="{color: record.identify? 'red':'rgba(0, 0, 0, 0.65)'}">
									{{ record.nickname }} <br /> {{ record.mobile }}
								</div>

							</template>
						</a-table-column>
						<a-table-column key="roleStr" title="用户等级" data-index="roleStr" align="center"></a-table-column>
						<a-table-column key="chargeStr" title="业绩总奖金" data-index="chargeStr" align="center"></a-table-column>
						<a-table-column key="child" title="业绩金额" data-index="child">
							<template slot-scope="text, record">
								<a-space direction="vertical">
									<a-space v-for="item in record.child || []" :key="item.productId">
										<a-avatar shape="square" :size="40" :src="item.banner"></a-avatar>
										<span> {{ item.chargeStr }}</span>
									</a-space>
								</a-space>
							</template>

						</a-table-column>
					</a-table>
				</template>
				<template v-if="modalUpdate.type == 'commission'">
					<div class="ant-table-wrapper">
						<div class="ant-spin-nested-loading">
							<div class="ant-spin-container">
								<div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered ant-table-small">
									<div class="ant-table-content"><!---->
										<div class="ant-table-body">
											<table class="">
												<colgroup>
													<col width="40">
													<col>
													<col>
												</colgroup>
												<thead class="ant-table-thead">
													<tr>
														<th></th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">用户信息</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">级别</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">分佣总额</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">产品</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">分佣金额</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">手续费</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">分佣账户金额</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">分佣类型</span>
																</div>
															</span>
														</th>
													</tr>
												</thead>
												<tbody class="ant-table-tbody">
													<template v-for="(record,index) in modalUpdate.formdata.commissions">
														<tr class="ant-table-row" v-for="(item,idx) in record.child" :key="`${index}_${idx}`">
															<td :rowspan="record.child.length" v-if="idx === 0">
																<a-checkbox v-model="record.checked" />
															</td>
															<td :rowspan="record.child.length" v-if="idx === 0">
																<div v-if="!!record.nickname" :style="{color:record.identify === 1?'red':''}">
																	{{ record.nickname }}
																	<br />
																	{{ record.mobile }}
																</div>
															</td>
															<td :rowspan="record.child.length" v-if="idx === 0">
																<div>
																	{{ record.roleStr }}
																</div>
															</td>
															<td :rowspan="record.child.length" v-if="idx === 0">
																<div :style="{fontWeight: parseFloat(record.totalMoneyStr) > 0?'bold':''}">
																	{{ record.totalMoneyStr }}
																</div>
															</td>
															<td> <a-avatar shape="square" :size="40" :src="item.banner"></a-avatar> </td>
															<td>
																<a-space>
																	<span :style="{fontWeight: parseFloat(item.moneyStr) > 0?'bold':''}" @dblclick="onUpdateCommission('money', item, record)"> {{ numeral(item.moneyStr).format('0,0.00') }}</span>
																	<!-- <a href="javascript:;" @click="onUpdateCommission('money', item, record)"> <a-icon type="form" /> </a> -->
																</a-space>
															</td>
															<td>
																<a-space>
																	<span :style="{fontWeight: parseFloat(item.chargeStr) > 0?'bold':''}" @dblclick="onUpdateCommission('charge', item, record)"> {{ numeral(item.chargeStr).format('0,0.00')  }}</span>
																	<!-- <a href="javascript:;" @click="onUpdateCommission('charge', item, record)"> <a-icon type="form" /> </a> -->
																</a-space>
															</td>
															<td>
																<span :style="{fontWeight: parseFloat(item.incomeStr) > 0?'bold':''}"> {{ numeral(item.incomeStr).format('0,0.00')  }}</span>
															</td>
															<td> {{ item.incomeTypeStr }}</td>
														</tr>
													</template>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-if="modalUpdate.type == 'commission_confirm'">
					<div class="ant-table-wrapper">
						<div class="ant-spin-nested-loading">
							<div class="ant-spin-container">
								<div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered ant-table-small">
									<div class="ant-table-content"><!---->
										<div class="ant-table-body">
											<table class="">
												<thead class="ant-table-thead">
													<tr>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">用户信息</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">级别</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">分佣总额</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">产品</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">分佣金额</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">手续费</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">分佣账户金额</span>
																</div>
															</span>
														</th>
														<th>
															<span class="ant-table-header-column">
																<div>
																	<span class="ant-table-column-title">分佣类型</span>
																</div>
															</span>
														</th>
													</tr>
												</thead>
												<tbody class="ant-table-tbody">
													<template v-for="(record,index) in modalUpdate.formdata.commissions.filter(v=>v.checked)">
														<tr class="ant-table-row" v-for="(item,idx) in record.child" :key="`${index}_${idx}`">
															<td :rowspan="record.child.length" v-if="idx === 0">
																<div v-if="!!record.nickname" style="font-weight: bold;">
																	{{ record.nickname }}
																	<br />
																	{{ record.mobile }}
																</div>
															</td>
															<td :rowspan="record.child.length" v-if="idx === 0">
																<div style="font-weight: bold;">
																	{{ record.roleStr }}
																</div>
															</td>
															<td :rowspan="record.child.length" v-if="idx === 0">
																<div style="font-weight: bold;">
																	{{ record.totalMoneyStr }}
																</div>
															</td>
															<td> <a-avatar shape="square" :size="40" :src="item.banner"></a-avatar> </td>
															<td>
																<span> {{ numeral(item.moneyStr).format('0,0.00') }}</span>
															</td>
															<td>
																<span> {{ numeral(item.chargeStr).format('0,0.00')  }}</span>
															</td>
															<td> {{ numeral(item.incomeStr).format('0,0.00') }}</td>
															<td> {{ item.incomeTypeStr }}</td>
														</tr>
													</template>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-if="['stock_remarks'].includes(modalUpdate.type)">
					<a-form-model-item label="用户备注" prop="remarks"><a-textarea v-model="modalUpdate.formdata.remarks" placeholder="请输入用户备注" /></a-form-model-item>
					<a-form-model-item v-if="!modalUpdate.formdata.itemOne" label="发货人备注" prop="sendRemarks">
						<a-textarea v-model="modalUpdate.formdata.sendRemarks" placeholder="请输入发货人备注" />
					</a-form-model-item>
				</template>
				<template v-if="['roleId', 'remarks'].includes(modalUpdate.type)">
					<a-form-model-item label="备注" prop="remarks"><a-textarea v-model="modalUpdate.formdata.remarks" placeholder="请输入备注" /></a-form-model-item>
				</template>
				<template v-if="['service_charge'].includes(modalUpdate.type)">
					<a-form-model-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" label="服务费" prop="money" required>
						<a-input v-model="modalUpdate.formdata.money" placeholder="请输入服务费" addon-after="元" />
					</a-form-model-item>
					<a-form-model-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" label="产品类型" prop="productId" required>
						<a-select v-model="modalUpdate.formdata.productId" placeholder="请选择产品类型" :getPopupContainer="e => e.parentNode" allow-clear>
							<a-select-option :value="item.id" v-for="item in modalFormBalance.products" :key="item.id">
								<a-space>
									<a-avatar shape="square" :size="24" :src="item.banner"></a-avatar>
									{{ item.productName }}
								</a-space>
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" label="产品数量" prop="num" required>
						<a-input v-model="modalUpdate.formdata.num" placeholder="请输入产品数量" />
					</a-form-model-item>
					<a-form-model-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" label="市场服务费日期" prop="teamDate" required>
						<a-date-picker placeholder="请选择日期" :format="formatter" :value="modalUpdate.formdata.teamDate ? moment(modalUpdate.formdata.teamDate, formatter) : null" @change="e => (modalUpdate.formdata.teamDate = e ? e.format(formatter) : null)" :allowClear="false" />
					</a-form-model-item>
					<a-form-model-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" label="市场服务费人员" prop="serviceUserIds" required>
						<a-checkbox-group v-model="modalUpdate.formdata.serviceUserIds">
							<div v-for="user in modalUpdate.formdata.serviceUsers" :key="user.userId">
								<a-checkbox :value="user.userId">
									<span style="color:#F50" v-if="user.identify == 1"> 【{{ user.roleStr }}】{{ user.nickname }}({{ user.mobile }})</span>
									<span v-else> 【{{ user.roleStr }}】{{ user.nickname }}({{ user.mobile }})</span>
								</a-checkbox>
							</div>
						</a-checkbox-group>
					</a-form-model-item>
					<a-form-model-item :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" label="备注" prop="remarks">
						<a-textarea v-model="modalUpdate.formdata.remarks" placeholder="请输入备注" />
					</a-form-model-item>
				</template>
			</a-form-model>
			<div slot="footer">
				<a-button v-if="['stock_super', 'stock_type', 'stock_fee', 'stock_remarks'].includes(modalUpdate.type)" @click="onBack">返回</a-button>
				<a-button v-else @click="modalUpdate.visible = false">取消</a-button>
				<a-button type="primary" :loading="modalUpdate.loading" @click="onUpdate">
					{{ ['stock', 'stock_super', 'stock_type', 'commission', 'stock_fee'].includes(modalUpdate.type) ? '下一步' : '保存' }}
				</a-button>
			</div>
		</a-modal>

		<a-modal v-drag-modal :title="modal.query.type == 1 ? '云库存明细' : '实体库存明细'" :width="600" v-model="modal.visible" :footer="null" :bodyStyle="{ padding: '10px' }">
			<a-list bordered size="small" item-layout="horizontal" :data-source="modal.data.productId ? [modal.data] : []" style="max-width:700px">
				<a-list-item slot="renderItem" slot-scope="item, index">
					<a-space>
						<a-avatar shape="square" style="width:60px;height:48px" :src="item.banner" />
						{{ item.productName }}
						<a-tag :color="modal.query.type == 1 ? 'green' : 'orange'">库存： {{ modal.query.type == 1 ? item.goodsNum : item.entityNum }}</a-tag>
					</a-space>
				</a-list-item>
			</a-list>
			<a-table row-key="key" style="margin-top:10px" size="small" :data-source="modal.lists.data" :pagination="{ current: modal.lists.current_page, pageSize: modal.lists.per_page, total: modal.lists.total }" @change="onPageChange" :scroll="{ x: 'max-content' }">
				<a-table-column key="bizName" title="描述" data-index="bizName" />
				<a-table-column key="nums" title="数量" data-index="nums" />
				<a-table-column key="ctime" title="操作时间" data-index="ctime" align="center" />
				<a-table-column key="action" title="操作" align="center">
					<template slot-scope="text, record">
						<a-space v-if="record.bizId"><a-button size="small" @click="onOrder(record)">订单详情</a-button></a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-modal>

		<a-modal v-drag-modal :title="`修改库存 - ${modalFormStock.data.productName}`" v-model="modalFormStock.visible" :bodyStyle="{ padding: '10px' }">
			<a-form-model ref="formStock" :model="modalFormStock.formdata" :rules="modalFormStock.rules" v-bind="layout">
				<a-form-model-item label="云库存" prop="goodsNum"><a-input v-model="modalFormStock.formdata.goodsNum" placeholder="请输入实体库存" /></a-form-model-item>
				<a-form-model-item label="实体库存" prop="entityNum"><a-input v-model="modalFormStock.formdata.entityNum" placeholder="请输入实体库存" /></a-form-model-item>
			</a-form-model>
			<div slot="footer">
				<a-button @click="modalFormStock.visible = false">取消</a-button>
				<a-button type="primary" :loading="modalFormStock.loading" @click="onSubmitStock">保存</a-button>
			</div>
		</a-modal>

		<a-modal v-drag-modal title="余额明细" v-model="modalBalance.visible" :width="680" :footer="null" :bodyStyle="{ padding: '10px' }">
			<a-table :data-source="modalBalance.lists.data" :pagination="{ current: modalBalance.lists.current_page, pageSize: modalBalance.lists.per_page, total: modalBalance.lists.total }" @change="onBalancePageChange">
				<a-table-column key="bizTypeStr" title="描述" data-index="bizTypeStr" />
				<a-table-column key="money" title="金额" data-index="money" />
				<a-table-column key="ctime" title="操作时间" data-index="ctime" />
				<!-- <a-table-column key="statusStr" title="状态" data-index="statusStr" /> -->
				<a-table-column key="status" title="状态" data-index="status" v-if="modalBalance.params.type == 3">
					<template slot-scope="status, record">
						<a-tag color="orange" v-if="status == 0">{{ record.statusStr }}</a-tag>
						<a-tag color="cyan" v-else-if="status == 1">{{ record.statusStr }}</a-tag>
						<a-tag color="green" v-else>{{ record.statusStr }}</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="action" title="操作">
					<template slot-scope="text, record">
						<a-button type="link" v-if="record.show == 1" size="small" @click="onBalanceOrder(record)">明细</a-button>
					</template>
				</a-table-column>
			</a-table>
		</a-modal>

		<a-modal v-drag-modal title="订单详情" v-model="modalOrder.visible" :footer="null" :zIndex="1080" :bodyStyle="{ padding: '10px' }">
			<div class="order-item">
				<div class="header">
					<div class="sn">订单：{{ modalOrder.data.id }}</div>
					<div>{{ modalOrder.data.statusStr }}</div>
				</div>
				<div class="content">
					<img class="cover" :src="modalOrder.data.image" />
					<div class="title">
						<div class="item">用户姓名：{{ modalOrder.data.nickname || modalOrder.data.userName }}</div>
						<div class="item">手机号码：{{ modalOrder.data.mobile }}</div>
						<div class="stitle">
							<div>订单数量： {{ modalOrder.data.num }}</div>
							<div v-show="modalOrder.data.giveNum > 0">赠送数量： {{ modalOrder.data.giveNum }}</div>
						</div>
					</div>
				</div>
				<div class="footer">
					<div class="date">{{ modalOrder.data.ctime }}</div>
					<div class="total">
						<a-tooltip placement="top" :getPopupContainer="triggerNode => triggerNode.parentNode" v-if="modalOrder.data.blendRemarks">
							<template slot="title">
								<span>{{ modalOrder.data.blendRemarks }}</span>
							</template>
							<a-icon type="question-circle" />
						</a-tooltip>
						¥{{ modalOrder.data.money }}
					</div>
				</div>
			</div>
		</a-modal>

		<a-modal v-drag-modal title="用户余额" v-model="modalFormBalance.visible" :bodyStyle="{ padding: '10px' }">
			<a-form-model ref="formBalance" :model="modalFormBalance.formdata" :rules="modalFormBalance.rules" v-bind=" {
					labelCol: { span: 6 },
					wrapperCol: { span: 16 }
				}">
				<a-form-model-item label="余额" prop="money">
					<a-input v-model="modalFormBalance.formdata.money" placeholder="请输入余额" prefix="￥" :max-length="10" />
				</a-form-model-item>
				<a-form-model-item label="手续费" prop="charge" required>
					<a-radio-group v-model="modalFormBalance.formdata.charge">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="2">否</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="付款人" prop="drawee" required>
					<a-radio-group v-model="modalFormBalance.formdata.drawee">
						<a-radio :value="1">公司</a-radio>
						<a-radio :value="2">其他</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="购买用户" prop="mobile">
					<a-input v-model="modalFormBalance.formdata.mobile" placeholder="请输入手机号" />
				</a-form-model-item>
				<a-form-model-item label="购买产品" prop="productId">
					<a-select v-model="modalFormBalance.formdata.productId" placeholder="请选择购买产品" :getPopupContainer="e => e.parentNode" allow-clear>
						<a-select-option :value="item.id" v-for="item in modalFormBalance.products" :key="item.id">
							<a-space>
								<a-avatar shape="square" :size="24" :src="item.banner"></a-avatar>
								{{ item.productName }}
							</a-space>
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="购买数量" prop="num">
					<a-input v-model="modalFormBalance.formdata.num" placeholder="请输入购买数量" />
				</a-form-model-item>
				<a-form-model-item label="分佣类型" prop="type">
					<a-select v-model="modalFormBalance.formdata.type" placeholder="请选择分佣类型" :getPopupContainer="e => e.parentNode" allow-clear>
						<a-select-option :value="option" :key="option" v-for="option in ['差额奖','平级奖','货款','推荐奖','其它']">{{ option }}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="前台备注" prop="userRemark"><a-input v-model="modalFormBalance.formdata.userRemark" placeholder="请输入前台备注" /></a-form-model-item>
				<a-form-model-item label="验证码" prop="code"><a-input v-model="modalFormBalance.formdata.code" placeholder="请输入验证码" :max-length="4" /></a-form-model-item>
				<a-form-model-item label="后台备注" prop="remarks"><a-input v-model="modalFormBalance.formdata.remarks" placeholder="请输入后台备注" /></a-form-model-item>
			</a-form-model>
			<div slot="footer">
				<a-button @click="modalFormBalance.visible = false">取消</a-button>
				<a-button type="primary" :loading="modalFormBalance.loading" @click="onSubmitBalance">保存</a-button>
			</div>
		</a-modal>

		<a-modal v-drag-modal title="VIP升级记录" v-model="modalUpgrade.visible" :footer="null" :bodyStyle="{ padding: '10px' }">
			<a-table :data-source="modalUpgrade.data" :pagination="false">
				<a-table-column key="ctime" title="升级时间" data-index="ctime" />
				<a-table-column key="content" title="内容" data-index="content" />
				<a-table-column key="typeStr" title="类型" data-index="typeStr" />
			</a-table>
		</a-modal>

		<a-modal v-drag-modal title="上级列表" :width="860" v-model="modalSuperior.visible" :footer="null" :bodyStyle="{ padding: '10px' }">
			<a-table :data-source="modalSuperior.data" :pagination="false" size="small">
				<a-table-column key="level" title="层级" data-index="level" :width="90" />
				<a-table-column key="mobile" title="用户名" data-index="mobile" :width="280">
					<template slot-scope="text, record">
						{{ record.userId }}
						<a href="javascript:;" v-clipboard:copy="record.userId" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
						<br />
						{{ record.name }} （{{ record.mobile }}
						<a href="javascript:;" v-clipboard:copy="record.mobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
						）
					</template>
				</a-table-column>
				<a-table-column key="roleStr" title="级别" data-index="roleStr">
					<template slot-scope="text, record">
						<span v-if="record.identify === 1" style="color:red">{{ record.roleStr }}</span>
						<span v-else>{{ record.roleStr }}</span>
					</template>
				</a-table-column>
				<a-table-column key="cloudNum" title="云库存" data-index="cloudNum" />
				<a-table-column key="entityNum" title="实体库存" data-index="entityNum" />
				<a-table-column key="ctime" title="注册时间" data-index="ctime" :width="200" />
			</a-table>
		</a-modal>

		<a-modal v-drag-modal title="请您确认" v-model="modalConfirm.visible" :width="600" :bodyStyle="{ padding: '10px' }">
			<a-table :data-source="modalUpdate.formdata.stocks" :pagination="false" :scroll="{y:500}">
				<a-table-column key="productName" title="产品" data-index="productName" />
				<a-table-column key="cloudNum" data-index="cloudNum" align="center">
					<span slot="title" style="color: #1890ff">云库存购买数量</span>
					<template slot-scope="status, record">
						{{ record.cloudNum || '' }}
					</template>
				</a-table-column>
				<a-table-column key="cloudNumGive" data-index="cloudNumGive" align="center">
					<span slot="title" style="color: #1890ff">云库存赠送数量</span>
					<template slot-scope="status, record">
						{{ record.cloudNumGive || '' }}
					</template>
				</a-table-column>
				<a-table-column key="entityNum" data-index="entityNum" align="center">
					<span slot="title" style="color: #1890ff">实体库存数量</span>
					<template slot-scope="status, record">
						{{ record.entityNum || '' }}
					</template>
				</a-table-column>
			</a-table>
			<div slot="footer">
				<a-button @click="modalConfirm.visible = false">取消</a-button>
				<a-button type="primary" :loading="modalConfirm.loading" @click="onUpdateConfirm">确认无误</a-button>
			</div>
		</a-modal>
	</a-space>
</template>

<script>
	// import { createElement } from 'vue'
	import moment from 'moment';
	import { copy } from '@/utils';
	import { ROLES } from '@/utils/constants.js';
	import numeral from 'numeral'
	import { Modal } from 'ant-design-vue';

	const isDev = /localhost/.test(location.hostname)

	const moneyRegexp = /^(\-)?\d+(\.\d{1,2})?$/;
	const numberRegexp = /^\d+$/;

	const BALANCE_FORMDATA = {
		charge: undefined,
		drawee: undefined,
		money: '',
		code: '',
		remarks: '',
		mobile: '',
		productId: undefined,
		num: '',
		type: undefined,
		userRemark: '',
	};
	const STOCK_FORMDATA = {
		uid: '',
		productId: '',
		goodsNum: 0,
		entityNum: 0
	};

	const modalTitles = {
		user_remark: '用户备注',
		mobile: '手机号码',
		remarks: '备注',
		roleId: '用户等级',
		bondType: '保证金类型',
		vipNum: '推荐经销商数量',
		service_charge: '市场服务费',
		stock: '修改库存',
		stock_super: '选择上级',
		stock_type: '产品类型',
		stock_fee: '市场服务费人员',
		stock_remarks: '其他信息',
		realName: '实名信息',
		commission: '分佣信息',
		commission_confirm: '确认分佣信息'
	};

	const productIdMap = {
		5142806488141659: 1,
		5142806488141662: 2,
		5142806488141661: 3,
		5142806488141669: 4,
		5142806488141670: 5,
		5142806488141677: 6
	};
	const productNameMap = {
		5142806488141659: '【汤为丽】御膳汤',
		5142806488141662: '【五不高】二酯油',
		5142806488141661: '【五不高】清畅汤',
		5142806488141669: '【五不高】80型装油',
		5142806488141670: '【五不高】50型装油',
		5142806488141677: '【东方㿟】不老皇后'
	};

	export default {
		data() {
			const numberValidator = (rule, value, callback) => {
				const { roleId } = this.info;
				if (roleId == 0 || numberRegexp.test(value)) {
					callback();
				} else {
					callback(new Error('请输入合法数字'));
				}
			};
			const moneyValidator = (rule, value, callback) => {
				if (moneyRegexp.test(value)) {
					callback();
				} else {
					callback(new Error('请输入正确的金额'));
				}
			};
			const codeValidator = (rule, value, callback) => {
				if (value && !/^\d{4}$/.test(value)) {
					callback(new Error('请输入正确的验证码'));
				} else {
					callback();
				}
			};
			const chargeValidator = (rule, value, callback) => {
				if (value == 1 || value == 2) {
					callback(new Error('请选择是否需要手续费'));
				} else {
					callback();
				}
			};
			const draweeValidator = (rule, value, callback) => {
				if (value == 1 || value == 2) {
					callback(new Error('请选择付款人'));
				} else {
					callback();
				}
			};
			const formatter = 'YYYY-MM-DD';
			return {
				ROLES,
				info: {},
				productIdMap,
				productNameMap,
				formatter,
				formdata: {
					id: '',
					roleId: 0,
					mobile: '',
					bondType: 0,
					vipNum: 0,
					goodsNum: 0,
					entityNum: 0,
					teamType: 1,
					teamDate: undefined,
					teamMoney: undefined,
					realName: 0,
					remarks: '',
					user_remark: '',
					month: '',
					teamDate: moment().format(formatter)
				},
				rules: {
					goodsNum: [{
						required: true,
						validator: numberValidator,
						trigger: 'blur'
					}],
					entityNum: [{
						required: true,
						validator: numberValidator,
						trigger: 'blur'
					}],
					vipNum: [{
						required: true,
						validator: numberValidator,
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入用户手机号',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入用户手机号',
						trigger: 'blur'
					}]
				},
				layout: {
					labelCol: {
						span: 4
					},
					wrapperCol: {
						span: 18
					}
				},
				modal: {
					visible: false,
					data: {},
					query: {
						page: 1,
						productId: undefined
					},
					lists: {
						data: [],
						current_page: 1,
						per_page: 10,
						total: 0
					}
				},
				modalFormStock: {
					visible: false,
					loading: false,
					formdata: copy(STOCK_FORMDATA),
					data: {},
					rules: {
						goodsNum: [{
							required: true,
							validator: numberValidator,
							trigger: 'blur'
						}],
						entityNum: [{
							required: true,
							validator: numberValidator,
							trigger: 'blur'
						}]
					}
				},
				modalBalance: {
					visible: false,
					query: {
						page: 1
					},
					params: {
						type: 0
					},
					lists: {
						data: [],
						current_page: 1,
						per_page: 10,
						total: 0
					}
				},
				modalFormBalance: {
					visible: false,
					loading: false,
					formdata: copy(BALANCE_FORMDATA),
					products: [],
					rules: {
						money: [{
							required: true,
							validator: moneyValidator,
							trigger: 'blur'
						}],
						charge: [{
							required: true,
							message: '请选择是否需要手续费',
							trigger: 'change'
						}],
						drawee: [{
							required: true,
							message: '请选择付款人',
							trigger: 'change'
						}],
						code: [{
							validator: codeValidator,
							trigger: 'blur'
						}],
						remarks: [{
							required: true,
							message: '请输入备注',
							trigger: 'blur'
						}]
					}
				},
				modalOrder: {
					visible: false,
					data: {}
				},
				modalUpgrade: {
					visible: false,
					data: []
				},
				modalSuperior: {
					visible: false,
					data: []
				},

				modalUpdate: {
					loading: false,
					visible: false,
					title: '',
					type: '',
					formdata: {
						productId: undefined,
						num: '',
						user_remark: '',
						mobile: '',
						roleId: undefined,
						remarks: '',
						bondType: undefined,
						vipNum: undefined,
						stocks: [],
						orderMoney: undefined,
						companyMoney: undefined,
						earnestMoney: undefined,
						inventory: 1,

						supers: [],
						types: [],
						services: [],
						commissions: [],
						serviceUids: [],

						senderUid: undefined,
						itemOne: undefined,
						itemTwo: undefined,
						productInfo: [],
						sendRemarks: '',
						serviceCharge: [],

						serviceUsers: [],
						serviceUserIds: [],
						money: '',
						teamDate: moment().format(formatter)
					},
					stock_formdata: {
						productInfo: [],
						senderUid: '',
						itemOne: undefined,
						itemTwo: undefined,
						serviceCharge: []
					}
				},

				modalConfirm: {
					visible: false,
					loading: false,
					data: {}
				},

				edit: {
					loading: false,
					field: null
				},

				fromRouteName: null,

				remarks: []
			};
		},
		computed: {
			cardTitle() {
				return this.$route.meta.title || '';
			},
			readonly() {
				return false;
			}
		},
		async mounted() {
			this.getInfo();
		},
		methods: {
			moment,
			numeral,
			async getInfo() {
				const id = this.$route.params.id;
				if (id) {
					const response = await this.$api.get(`/user_detail/${id}`);
					if (response && response.code == 200) {
						const { roleId, realName, remarks, ...odata } = response.data;
						this.remarks = (Array.isArray(remarks) ? remarks : []).map((item, index) => {
							item.index = index + 1;
							return item;
						});
						odata.totalGoodsNum = odata.goodsNum.map(v => v.goodsNum).reduce((a, b) => a + b);
						odata.totalEntityNum = odata.goodsNum.map(v => v.entityNum).reduce((a, b) => a + b);
						odata.roleId = +roleId;
						odata.realName = +realName;
						odata.remarks = '';
						odata.uid = odata.id;
						this.formdata = Object.assign(this.formdata, odata);
						this.info = Object.assign({}, odata);
					}
				}
			},
			async onEdit(type) {
				const { formdata } = this.modalUpdate;
				let res = null;
				switch (type) {
					case 'user_remark':
						formdata[type] = this.info.userRemark || undefined;
						break;
					case 'stock':
						if (!this.modalUpdate.visible) {
							formdata.stocks = [];
							formdata.orderMoney = undefined
							formdata.companyMoney = undefined
							formdata.earnestMoney = undefined
							formdata.inventory = 1

							if (isDev) {
								formdata.orderMoney = 17913
								formdata.companyMoney = 17913
								formdata.earnestMoney = 2000
							}
							this.info.goodsNum.forEach(goods => {
								let o = {
									...goods,
									cloudNum: undefined,
									cloudNumGive: undefined,
									entityNum: undefined
								}
								if (isDev) {
									if (o.productId == '5142806488141659') {
										o.cloudNum = 20
									}
									if (o.productId == '5142806488141662') {
										o.cloudNumGive = 10
										o.entityNum = 10
									}
									if (o.productId == '5142806488141677') {
										o.cloudNum = 27
										o.cloudNumGive = 10
									}
								}
								formdata.stocks.push(o);
							});
						}
						break;
					case 'stock_super':
						res = await this.$api.get(`/user_super_list/${this.info.uid}`).catch(e => {
							this.modalUpdate.loading = false;
						});
						if (res.code == 200) {
							formdata.supers = res.data || [];
						}
						break;
					case 'stock_type':
						res = await this.$api.get(`/user_add_type`).catch(e => {
							this.modalUpdate.loading = false;
						});
						if (res.code == 200) {
							formdata.types = res.data || [];
						}
						break;
					case 'stock_fee':
						res = await this.$api
							.post(`/user_market_service`, {
								productInfo: JSON.stringify(formdata.productInfo),
								senderUid: formdata.senderUid,
								uid: this.info.uid,
								itemOne: formdata.itemOne,
								itemTwo: formdata.itemTwo,
								orderMoney: formdata.orderMoney,
								companyMoney: formdata.companyMoney,
								earnestMoney: formdata.earnestMoney,
								inventory: formdata.inventory
							})
							.catch(e => {
								this.modalUpdate.loading = false;
							});
						if (res.code == 200) {
							formdata.services = res.data.service || [];
							res.data.commission = res.data.commission || []
							res.data.commission.forEach(item => {
								item.checked = item.totalMoney > 0;
								item.child = item.child || []
								item.child.forEach(vitem => {
									vitem.edit = false
									vitem.m = parseFloat(vitem.money || '0')
									vitem.c = parseFloat(vitem.charge || '0')
									vitem.i = parseFloat(vitem.income || '0')
								})
							})
							formdata.commissions = res.data.commission || []
						} else {
							this.modalUpdate.loading = false;
							this.$message.error(res.msg || '操作失败');
							return;
						}
						break;
					case 'roleId':
						this.modalUpdate.formdata.roleId = this.info.roleId || undefined;
						this.modalUpdate.formdata.remarks = '';
						break;
					case 'service_charge':
						if (this.modalFormBalance.products.length == 0) {
							this.modalFormBalance.products = (await this.$api.get('/user_query_product/7')).data || []
						}
						res = await this.$api.get(`/user_service_charge_superior/${this.info.uid}`).catch(e => {
							this.modalUpdate.loading = false;
						});
						formdata.productId = undefined;
						formdata.num = '';
						formdata.remarks = '';
						formdata.money = '';
						formdata.serviceUserIds = [];
						if (res.code == 200) {
							formdata.serviceUsers = res.data || [];
						}
						break;
					case 'vipNum':
						formdata.vipNum = undefined;
						break;
					default:
						this.modalUpdate.formdata[type] = this.info[type] || undefined;
				}
				this.modalUpdate.loading = false;
				this.modalUpdate.title = modalTitles[type];
				this.modalUpdate.type = type;
				this.modalUpdate.visible = true;
			},
			async onUpdate() {
				this.modalUpdate.loading = true;

				const { uid } = this.info;
				const { type, formdata } = this.modalUpdate;
				const {
					mobile,
					roleId,
					remarks,
					user_remark,
					vipNum,
					stocks,
					supers,
					services,
					senderUid,
					itemOne,
					itemTwo,
					serviceUids,
					sendRemarks,
					money,
					serviceUserIds,
					teamDate,
					productId,
					num
				} = formdata;
				let response = null;
				let res = null;
				switch (type) {
					case 'stock':
						let pdata = [];
						let error = [];

						if (formdata.orderMoney && !/^\d+(\.?\d{1,2})?$/.test(formdata.orderMoney)) {
							this.modalUpdate.loading = false;
							return this.$message.error('请输入正确的订单金额');
						}

						if (formdata.companyMoney && !/^\d+(\.?\d{1,2})?$/.test(formdata.companyMoney)) {
							this.modalUpdate.loading = false;
							return this.$message.error('请输入正确的公司收款金额');
						}

						if (formdata.earnestMoney && !/^\d+(\.?\d{1,2})?$/.test(formdata.earnestMoney)) {
							this.modalUpdate.loading = false;
							return this.$message.error('请输入正确的保证金');
						}

						stocks.forEach(item => {
							let { productId, cloudNum, entityNum, cloudNumGive } = item;
							let o = {
								productId,
								cloudNum: cloudNum || 0,
								entityNum: entityNum || 0,
								cloudNumGive: cloudNumGive || 0
							};
							if (/^\d*$/.test(o.cloudNumGive)) {
								o.cloudNumGive = parseInt(o.cloudNumGive);
								if (o.cloudNumGive > 10000) {
									error.push('赠送数量不能超过1w');
								}
							} else {
								error.push('赠送数量格式不正确');
							}
							if (/^-?\d*$/.test(o.entityNum)) {
								o.entityNum = parseInt(o.entityNum);
								if (o.entityNum > 30000) {
									error.push('实体库存数量不能超过3w');
								} else if (o.entityNum < -30000) {
									error.push('实体库存数量不能超过3w');
								}
							} else {
								error.push('实体库存数量格式不正确');
							}
							if (/^-?\d*$/.test(o.cloudNum)) {
								o.cloudNum = parseInt(o.cloudNum);
								if (o.cloudNum > 30000) {
									error.push('云库存数量不能超过3w');
								} else if (o.cloudNum < -30000) {
									error.push('云库存数量不能超过3w');
								}
							} else {
								error.push('云库存数量格式不正确');
							}
							if (o.cloudNum == 0 && o.entityNum == 0 && o.cloudNumGive == 0) {} else {
								pdata.push(o);
							}
						});
						if (error.length) {
							this.modalUpdate.loading = false;
							return this.$message.error(error[0]);
						}
						if (pdata.length === 0) {
							this.modalUpdate.loading = false;
							return this.$message.error('请输入需要修改的产品库存数量');
						}
						formdata.productInfo = pdata;
						formdata.senderUid = undefined;
						formdata.itemOne = undefined;
						formdata.itemTwo = undefined;
						formdata.serviceCharge = [];
						formdata.services = [];
						formdata.remarks = undefined;
						formdata.sendRemarks = undefined;
						return this.onEdit('stock_super');
						break;
					case 'stock_super':
						if (!senderUid) {
							this.modalUpdate.loading = false;
							return this.$message.error('请选择上级');
						}
						formdata.itemOne = undefined;
						formdata.itemTwo = undefined;
						formdata.serviceCharge = [];
						formdata.services = [];
						formdata.serviceUids = [];
						formdata.remarks = undefined;
						formdata.sendRemarks = undefined;
						const sender = supers.find(v => v.userId == senderUid);
						if (sender.mobile == '18888888888') {
							return this.onEdit('stock_type');
						} else {
							return this.onEdit('stock_fee');
						}
						break;
					case 'stock_type':
						if (itemOne && itemTwo) {
							Object.assign(formdata, { itemOne, itemTwo });
							return this.onEdit('stock_fee');
						} else {
							this.modalUpdate.loading = false;
							return this.$message.error('请选择产品类型');
						}
						break;
					case 'stock_fee':
						if (services.length > 0 && serviceUids.length === 0) {
							this.modalUpdate.loading = false;
							return this.$confirm({
								title: '确定不选择市场服务费人员吗？',
								onOk: () => {
									formdata.serviceCharge = [];
									if (formdata.commissions.length > 0) {
										return this.onEdit('commission');
									}
									this.onEdit('stock_remarks');
								}
							});
						}
						formdata.serviceCharge = services
							.filter(item => {
								return serviceUids.includes(item.userId);
							})
							.map(item => {
								return {
									userId: item.userId,
									charge: item.charge,
									child: (item.child || []).map(vitem => {
										return {
											productId: vitem.productId,
											charge: vitem.charge
										}
									})
								};
							});
						if (formdata.commissions.length > 0) {
							return this.onEdit('commission');
						}
						return this.onEdit('stock_remarks');
						break;
					case 'commission':
						if (formdata.commissions.some(v => v.checked)) {
							this.onEdit('commission_confirm')
						} else {
							this.onEdit('stock_remarks')
						}
						break;
					case 'commission_confirm':
						this.onEdit('stock_remarks')
						break;
					case 'stock_remarks':
						if (!remarks) {
							this.modalUpdate.loading = false;
							return this.$message.error('请输入备注内容');
						}
						const fdata = {
							uid,
							productInfo: JSON.stringify(formdata.productInfo),
							senderUid: formdata.senderUid,
							serviceCharge: JSON.stringify(formdata.serviceCharge),
							itemOne: formdata.itemOne,
							itemTwo: formdata.itemTwo,
							remarks,
							sendRemarks,
							orderMoney: formdata.orderMoney,
							companyMoney: formdata.companyMoney,
							earnestMoney: formdata.earnestMoney,
							inventory: formdata.inventory
						};
						// const vService = formdata.serviceCharge
						const vCommissions = formdata.commissions.filter(v => v.checked).map(item => {
							return {
								userId: item.userId,
								totalMoney: (parseFloat(item.totalMoneyStr || '0') * 100).toFixed(0),
								roleId: item.roleId,
								child: item.child.map(vitem => {
									const vo = {
										productId: vitem.productId,
										income: (parseFloat(vitem.incomeStr || '0') * 100).toFixed(0),
										charge: (parseFloat(vitem.chargeStr || '0') * 100).toFixed(0),
										incomeType: vitem.incomeType
									}
									vo.insertType = vo.charge == vitem.c && vo.income == vitem.i ? 0 : 2
									return vo
								})
							}
						})
						fdata.commission = JSON.stringify(vCommissions)
						// console.info(fdata)
						// return
						this.modalConfirm.data = fdata;
						this.modalConfirm.visible = true;

						break;
					case 'user_remark':
						response = await this.$api.post(`/user_remark`, {
							uid,
							remark: user_remark
						});
						break;
					case 'mobile':
						response = await this.$api
							.post(`/user_update_mobile`, {
								uid,
								mobile
							})
							.catch(e => {
								this.modalUpdate.loading = false;
							});
						break;
					case 'roleId':
						response = await this.$api
							.post(`/user_update_grade`, {
								uid,
								roleId,
								remarks
							})
							.catch(e => {
								this.modalUpdate.loading = false;
							});
						break;
					case 'remarks':
						response = await this.$api
							.post(`/user_update_remarks`, {
								uid,
								remarks
							})
							.catch(e => {
								this.modalUpdate.loading = false;
							});
						break;
					case 'service_charge':
						if (!money) {
							this.modalUpdate.loading = false;
							return this.$message.error('请输入服务费');
						}
						if (!productId) {
							this.modalUpdate.loading = false;
							return this.$message.error('请选择产品类型');
						}
						if (!num) {
							this.modalUpdate.loading = false;
							return this.$message.error('请输入产品数量');
						}
						if (!teamDate) {
							this.modalUpdate.loading = false;
							return this.$message.error('请选择市场服务费日期');
						}
						if (serviceUserIds.length == 0) {
							this.modalUpdate.loading = false;
							return this.$message.error('请选择服务费人员');
						}
						response = await this.$api
							.post(`/user_service_charge_insert`, {
								uid,
								money,
								remarks,
								teamDate,
								productId,
								num,
								serviceCharge: JSON.stringify(serviceUserIds.map(userId => ({ userId })))
							})
							.catch(e => {
								this.modalUpdate.loading = false;
							});
						break;
					case 'realName':
						response = await this.$api.post(`/user_real_name/${uid}`).catch(e => {
							this.modalUpdate.loading = false;
						});
						break;
					case 'vipNum':
						response = await this.$api
							.post(`/user_recommend_num`, {
								uid: uid,
								num: vipNum
							})
							.catch(e => {
								this.modalUpdate.loading = false;
							});
						break;
				}
				if (response && response.code == 200) {
					this.getInfo();
					this.$message.success('操作成功');
					this.modalUpdate.visible = false;
				}
				this.modalUpdate.loading = false;
			},
			async onUpdateRealName() {
				this.modalUpdate.type = 'realName';
				this.onUpdate();
			},
			async onUpdateConfirm() {
				this.modalConfirm.loading = true;
				const response = await this.$api.post(`/user_update_stock`, this.modalConfirm.data).catch(e => {
					this.modalConfirm.loading = false;
				});
				if (response && response.code == 200) {
					this.getInfo();
					this.$message.success('操作成功');
					this.modalUpdate.formdata.senderUid = undefined;
					this.modalUpdate.formdata.itemOne = undefined;
					this.modalUpdate.formdata.itemTwo = undefined;
					this.modalUpdate.formdata.serviceCharge = [];
					this.modalUpdate.formdata.services = [];
					this.modalUpdate.formdata.serviceUids = [];
					this.modalUpdate.formdata.remarks = '';
					this.modalUpdate.formdata.sendRemarks = '';
					this.modalConfirm.visible = false;
					this.modalConfirm.loading = false;
					this.modalUpdate.visible = false;
				}
			},
			async onBalance(type) {
				if (this.modalBalance.params.type != type) {
					this.modalBalance.query.page = 1;
					this.modalBalance.lists.data = [];
				}
				this.modalBalance.params.type = type;
				if (this.modalBalance.lists.data.length === 0) {
					this.getBalances(type);
				}
				this.modalBalance.visible = true;
			},
			async getBalances(type) {
				type = type || this.modalBalance.params.type || 1;
				const response = await this.$api.get(`/user_account_detail/${this.info.id}/${type}`, {
					params: this.modalBalance.query
				});
				this.modalBalance.lists = response.data || {};
			},
			onBalancePageChange(pagination = {}) {
				this.modalBalance.query.page = pagination.current;
				this.modalBalance.query.size = pagination.pageSize;
				this.getBalances();
			},

			async onUpgrade() {
				if (this.modalUpgrade.data.length === 0) {
					this.getUpgrades();
				}
				this.modalUpgrade.visible = true;
			},
			async getUpgrades() {
				const response = await this.$api.get(`/user_change_log/${this.info.id}`);
				this.modalUpgrade.data = response.data || [];
			},

			async onSuperiors() {
				if (this.modalSuperior.data.length === 0) {
					this.getSuperiors();
				}
				this.modalSuperior.visible = true;
			},
			async getSuperiors() {
				const response = await this.$api.get(`/user_superior_list/${this.info.id}`);
				this.modalSuperior.data = response.data || [];
			},
			async onStock(type, record) {
				this.modal.query.page = 1;
				this.modal.query.type = type;
				this.modal.query.productId = record.productId;
				this.modal.data = record;
				this.getStocks(type);
				this.modal.visible = true;
			},
			async getStocks(type) {
				const response = await this.$api.get(`/user_goods_detail/${this.info.id}`, {
					params: this.modal.query
				});
				response.data.data.forEach((item, index) => {
					item.key = Math.random()
						.toString(16)
						.substr(2);
					item.index = index;
				});
				this.modal.lists = response.data || {};
			},
			onPageChange(pagination = {}) {
				this.modal.query.page = pagination.current;
				this.modal.query.size = pagination.pageSize;
				this.getStocks();
			},
			async onOrder(record) {
				const response = await this.$api.get(`/recommend_order_detail`, {
					params: {
						id: record.bizId,
						bizType: record.bizType,
						uid: record.userId
					}
				});
				if (response && response.code == 200) {
					this.modalOrder.data = response.data;
					this.modalOrder.visible = true;
				} else {
					this.$message.error(response.msg);
				}
			},
			async onBalanceOrder(record) {
				const response = await this.$api.get(`/user_order_detail/${record.bizId}`);
				if (response && response.code == 200) {
					this.modalOrder.data = response.data;
					this.modalOrder.visible = true;
				} else {
					this.$message.error(response.msg);
				}
			},
			async onOfflineSet(pass) {
				this.$confirm({
					title: '提示',
					content: pass ? '确定开启线下支付通道吗？' : '确定取消线下支付通道吗？',
					onOk: async () => {
						const response = await this.$api.get(`/user_upgrade_set/${this.info.id}`);
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							this.getInfo();
						}
					}
				});
			},
			async onUnbindWechat() {
				this.$confirm({
					title: '提示',
					content: '确定要解绑微信吗？',
					onOk: async () => {
						const response = await this.$api.get(`/user_unbound_weChat/${this.info.id}`);
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							this.getInfo();
						}
					}
				});
			},
			async addRemarks() {
				this.$refs.form.validate(async valid => {
					if (valid) {
						const { id, remarks } = this.formdata;
						const response = await this.$api.post('/user_update_message', { id, remarks });
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							this.getInfo();
						}
					}
				});
			},
			async onSubmit() {
				this.$refs.form.validate(async valid => {
					if (valid) {
						const keys = ['roleId', 'goodsNum', 'entityNum', 'vipNum', 'mobile', 'bondType', 'teamType', 'teamDate', 'teamMoney', 'realName', 'remarks'];
						const data = {
							id: this.formdata.id
						};
						keys.forEach(key => {
							data[key] = this.formdata[key] || 0;
						});
						if (data.teamDate && moment(data.teamDate).isValid() && data.teamMoney > 0) {
							data.teamDate = moment(data.teamDate).format(this.formatter);
						} else {
							delete data.teamDate;
							delete data.teamType;
						}
						const response = await this.$api.post('/user_update_message', data);
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							this.getInfo();
							setTimeout(() => {
								this.$router.replace(`/users/${data.id}`);
							}, 1000);
						}
					}
				});
			},
			async onBack() {
				switch (this.modalUpdate.type) {
					case 'stock_super':
						this.modalUpdate.formdata.sender = [];
						this.modalUpdate.formdata.services = [];
						this.modalUpdate.formdata.itemOne = undefined;
						this.modalUpdate.formdata.itemTwo = undefined;
						this.modalUpdate.formdata.remarks = undefined;
						this.onEdit('stock');
						break;
					case 'stock_fee':
					case 'stock_type':
						this.modalUpdate.formdata.services = [];
						this.modalUpdate.formdata.itemOne = undefined;
						this.modalUpdate.formdata.itemTwo = undefined;
						this.modalUpdate.formdata.remarks = undefined;
						this.onEdit('stock_super');
						break;
					case 'stock_remarks':
						this.modalUpdate.formdata.services = [];
						this.modalUpdate.formdata.itemOne = undefined;
						this.modalUpdate.formdata.itemTwo = undefined;
						this.modalUpdate.formdata.remarks = undefined;
						this.onEdit('stock_fee');
						break;
				}
				// this.modalUpdate.visible = false;
			},
			async onBalanceForm(type = 6) {
				this.modalFormBalance.formdata = Object.assign({ uid: this.info.id, system: type == 6 ? 1 : 2 }, BALANCE_FORMDATA);
				this.modalFormBalance.products = (await this.$api.get(`/user_query_product/${type}`)).data || []
				this.modalFormBalance.visible = true;
			},
			async onSubmitBalance() {
				this.$refs.formBalance.validate(async valid => {
					if (valid) {
						this.modalFormBalance.loading = true;
						const response = await this.$api.post('/add_user_balance', this.modalFormBalance.formdata).catch(e => {
							this.modalFormBalance.loading = false;
						});
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							this.modalBalance.lists.data = [];
							this.getInfo();
							this.modalFormBalance.visible = false;
						} else {
							this.$message.error(response.msg);
						}
						this.$nextTick(() => {
							this.modalFormBalance.loading = false;
						});
					}
				});
			},
			async onUpdateStock(record) {
				this.modalFormStock.data = record;
				this.modalFormStock.formdata = Object.assign({}, STOCK_FORMDATA, {
					uid: this.info.id,
					productId: record.productId,
					goodsNum: record.goodsNum,
					entityNum: record.entityNum
				});
				this.modalFormStock.visible = true;
			},
			async onSubmitStock() {
				this.$refs.formStock.validate(async valid => {
					if (valid) {
						this.modalFormStock.loading = true;
						const response = await this.$api.post('/user_update_stock', this.modalFormStock.formdata).catch(e => {
							this.modalFormStock.loading = false;
						});
						if (response && response.code == 200) {
							this.getInfo();
							this.$message.success('操作成功');
							this.modalFormStock.visible = false;
						} else {
							this.$message.error(response.msg);
						}
						this.$nextTick(() => {
							this.modalFormStock.loading = false;
						});
					}
				});
			},
			async onUpdateCommission(type, item, record) {
				let val = type == 'money' ? item.moneyStr : item.chargeStr
				this.$confirm({
					title: '修改' + (type == 'money' ? '分佣金额' : '手续费'),
					content: this.$createElement('a-input', { props: { value: val }, on: { change: e => val = e.target.value } }),
					onOk: () => {
						item[`${type}Str`] = parseFloat(val || '0')
						record.totalMoneyStr = 0
						record.child.forEach(vitem => {
							vitem.incomeStr = parseFloat(vitem.moneyStr || '0') - parseFloat(vitem.chargeStr || '0')
							record.totalMoneyStr += parseFloat(vitem.incomeStr || '0')
						})
					}
				})
			}
		}
	};
</script>

<style lang="less">
	.ant-cascader-menus,
	.ant-select-dropdown {
		z-index: 1600;
	}

	.details {
		display: flex;
		flex-wrap: wrap;

		.upload {
			width: 128px;
			height: 128px;
		}

		.image,
		.video {
			position: relative;
			width: 128px;
			height: 128px;
			margin-right: 10px;
			margin-bottom: 10px;
			border: #eeeeee 1px dotted;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;

			.remove {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				background-color: rgba(0, 0, 0, 0.5);
				display: none;
				align-items: center;
				justify-content: center;
			}

			&:hover .remove {
				display: flex;
			}

			img,
			video {
				width: 128px;
				height: 128px;
				object-fit: contain;
			}
		}
	}

	.order-item {
		padding: 24px 30px 30px 24px;

		.header {
			display: flex;
			align-items: center;
		}

		.content {
			margin-top: 10px;
			display: flex;
		}

		.footer {
			margin-top: 10px;
			display: flex;
			align-items: center;
		}

		.cover {
			display: block;
			width: 100px;
			height: 100px;
			border-radius: 4px;
		}

		.sn {
			flex: 1;
			color: #838383;
			font-size: 13px;
		}

		.status {
			font-size: 14px;
			font-weight: 500;
			color: #fc873a;
		}

		.title {
			margin-left: 10px;
			color: #333333;
			font-size: 14px;
			flex: 1;
			line-height: 22px;
			min-width: 0;

			.item {
				height: 24px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				.label {
					float: left;
					width: 60px;
					text-align: justify;

					&::after {
						content: '';
						width: 100%;
						display: inline-block;
						height: 0;
					}
				}
			}

			.pname {
				line-height: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		.stitle {
			color: #555555;
			margin-top: 10px;
			font-size: 13px;

			&+.stitle {
				margin-top: 4px;
			}
		}

		.meta {
			// width: 158px;
			text-align: right;
			color: #666666;
			padding-top: 16px;
		}

		.price {
			font-size: 14px;
		}

		.quantity {
			margin-top: 16px;
			font-size: 13px;
		}

		.date {
			flex: 1;
			color: #999999;
			font-size: 13px;
		}

		.total {
			font-size: 16px;
			font-weight: 500;
			color: #333333;

			&::before {
				font-size: 13px;
				font-weight: 400;
				content: '实付金额：';
			}
		}
	}
</style>